import { useEffect, useState, useContext } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './App.css';
import './css/app_sp.css';
import './css/scrollbar.css';

import Approvals from './pages/ApprovalsPage';
import ApprovalUserList from './pages/ApprovalUserListPage';
import Kintai from "./pages/Kintai";
import Mst_departmentMstPage from './pages/Mst_departmentMstPage';
import RequestKintai from "./pages/RequestKintai";
import RequestShiftMstPage from './pages/RequestShiftMstPage';
import SignIn from './pages/SignIn'
import ExportCsvPage from './pages/ExportCsvPage';
import * as commonFnc from './common/commonFnc';
import Mst_publicHolidayMstPage from './pages/Mst_publicHolidayMstPage';
import ClockInPage from './pages/ClockinPage';
import EnvMstPage from './pages/Mst_envMstPage';
import ExportCSVMstPage from './pages/Mst_exportCSVMstPage';
import JarnalViewPage from './pages/jarnalViewPage';

import SP_KintaiPage from './pages/SP_KintaiPage';
import SP_KintaiListPage from './pages/SP_KintaiListPage';


import {GlobalProvider} from './globalState';
import ClockinLogPage from './pages/ClockinLogPage';
import ImportShiftPage from './pages/ImportShiftPage';
import InformationPage from './pages/InformationPage';
import InformationEditPage from './pages/InformationEditPage';
import Mst_UserMstPage from './pages/Mst_UserMstPage';
import ShiftTablePage from './pages/ShiftTablePage';
import SP_ShiftTablePage from './pages/SP_ShiftTablePage';

const APP_KEY = "KintaiLoginUser"

function App() {


  // ログイン中のユーザIDをローカルに保存
  const LOGINUSER = localStorage.getItem(APP_KEY)
  useEffect(()=>{
    if (LOGINUSER !== ""){
      localStorage.setItem(APP_KEY, LOGINUSER)
    }
  },[LOGINUSER])
  
  
  const [triggerReloadData, setTriggerReloadData] = useState(false);

  const [login_user_id, setLogin_user_id] = useState(LOGINUSER);
  const [Login_user_info, setLogin_user_info] = useState("");
  const [display_user_info, setDisplay_user_info] = useState("");
  const [shiftStartDay, setShiftStartDay] = useState(commonFnc.getShiftStartDay( new Date()));
  
  // 変なタイミングで動くためコメントアウト
  const unloadLog = (event) => {
    // IEしか動かない。
    // commonFnc.insertUserLog("INFO", login_user_id, __filename, "", "ログアウト", "画面遷移", "ブラウザを閉じた。", null, null);
  };
  useEffect(() => {

    window.addEventListener("beforeunload", unloadLog);
    return () => window.addEventListener('beforeunload', unloadLog);
  }, []);
  

  // 勤怠情報
  const [Kintai_bscs, setKintai_bscs] = useState([]);
  useEffect(async ()=>{
    const query = new URLSearchParams({login_user_id: login_user_id});
    await fetch(`/api/select/kintai_bsc?${query}`)
      .then((res) => res.json())
      .then((data) => setKintai_bscs(data));
    
    const queryGetLoginUser = new URLSearchParams({user_id: login_user_id});
    await fetch(`/api/select/login_user?${queryGetLoginUser}`)
      .then((res)=> res.json())
      .then((data)=>{setLogin_user_info(data)})
      // .then((data)=>{setLogin_user_info(data); return data;})
      // .then((data)=>setDisplay_user_info(data))
    
  },[login_user_id, triggerReloadData]);

  useEffect(()=>{
    setDisplay_user_info(Login_user_info)
  },[Login_user_info])
  
  // 勤怠一覧情報
  const [Kintai_bsc, setKintai_bsc] = useState({
    user_id: "",
    date: "",
    shift_type_cd: "",
    harf_holiday_shift_type_cd: "",
    paid_holiday_shift_type_cd: "",
    work_time_from: "",
    work_time_to: "",
    approval_status_cd: "",
    work_time: "",
    midnight_work_time: "",
    rest_time: "",
    over_time: "",
    midnight_over_time: "",
    behind_time: "",
    early_time: "",
    out_time: "",
    first_approval_userid: "",
    second_approval_userid: "",
    next_approval_userid: "",
    training_time: "",
    approval_note: "",
    count1: "",
    count2: "",
    count3: "",
    count4: "",
    count5: "",
  })
  
  // 残業申請情報
  const [Request_overtimes, setRequest_overtimes] = useState([])

  // 外出申請情報
  const [Kintai_outtime, setKintai_outtime] = useState({
    user_id: "",
    date: "",
    out_time_from: "",
    out_time_to: ""
  })
  
  // 有給申請情報
  const [Request_paid_holiday, setRequest_paid_holiday] = useState({
    user_id: "",
    date: "",
    request_paid_holiday_reason: ""
  })

  // ユーザ情報
  const [Users, setUsers] = useState([]);
  useEffect(()=>{
    fetch("api/select/users")
      .then((res)=> res.json())
      .then((data)=>setUsers(data))
  },[Login_user_info])

  // シフトマスタ一覧取得
  const [Shift_msts, setShift_msts] = useState([]);
  useEffect(()=>{
    fetch("/api/select/shift_mst")
      .then((res) => res.json())
      .then((data) => setShift_msts(data))
  },[]);

  // 部署マスタ一覧取得
  const [Department_msts, setDepartment_msts] = useState([]);
  useEffect(()=>{
    fetch("/api/select/department_msts")
      .then((res) => res.json())
      .then((data) => setDepartment_msts(data))
  },[]);

  // 打刻マスタ一覧取得
  const [Clockin_type_msts, setClockin_type_msts] = useState([]);
  useEffect(()=>{
    fetch("/api/select/clockin_type_msts")
      .then((res) => res.json())
      .then((data) => setClockin_type_msts(data))
  },[]);
        
  const [Clockin_info, setClockin_info] = useState({
      date: "",
      department_cd:"",
      user_id: "",
      time: "",
      shift_type_cd: "",
      clockin_type_cd: ""
  })

  // お知らせ情報取得
  const [InformationFromEnterprise, setInformationFromEnterprise] = useState();
  const [InformationFromSystem, setInformationFromSystem] = useState();

  useEffect(async ()=>{
    const infoRes = await fetch("/api/select/information_from_enterprise")
    const infoData = await infoRes.json();
    setInformationFromEnterprise(infoData);

    const infoSysRes = await fetch("/api/select/information_from_system")
    const infoSysData = await infoSysRes.json();
    setInformationFromSystem(infoSysData);
      
  },[])

  return (
    <GlobalProvider
      Login_user_info={Login_user_info}
    >
      <BrowserRouter>
        <Switch>
          {/* ログイン画面 */}
          <Route exact path="/">
            <SignIn 
              APP_KEY = {APP_KEY}
              setLogin_user_id={setLogin_user_id}
            />
          </Route>
          {/* 勤怠申請画面 */}
          <Route exact path="/RequestKintai">
            <RequestKintai 
              login_user_id={login_user_id}
              setLogin_user_id={setLogin_user_id}
              Shift_msts={Shift_msts} 
              setKintai_bscs={setKintai_bscs}
              Kintai_bsc={Kintai_bsc} 
              setKintai_bsc={setKintai_bsc}
              Request_overtimes={Request_overtimes}
              setRequest_overtimes={setRequest_overtimes}
              Kintai_outtime={Kintai_outtime}
              setKintai_outtime={setKintai_outtime}
              Request_paid_holiday={Request_paid_holiday}
              setRequest_paid_holiday={setRequest_paid_holiday}
              Login_user_info={Login_user_info}
              setLogin_user_info={setLogin_user_info}
              display_user_info={display_user_info}
              setDisplay_user_info={setDisplay_user_info}
              Department_msts={Department_msts}
              triggerReloadData={triggerReloadData}
              setTriggerReloadData={setTriggerReloadData}
              />
          </Route>
          {/* 勤怠一覧画面 */}
          <Route exact path="/Kintai">
            <Kintai 
              login_user_id={login_user_id}
              setLogin_user_id={setLogin_user_id}
              Kintai_bscs={Kintai_bscs} 
              setKintai_bscs={setKintai_bscs}
              Kintai_bsc={Kintai_bsc}
              setKintai_bsc={setKintai_bsc}
              Shift_msts={Shift_msts}
              Department_msts={Department_msts}
              setRequest_overtimes={setRequest_overtimes}
              Request_paid_holiday={Request_paid_holiday}
              setRequest_paid_holiday={setRequest_paid_holiday}
              Login_user_info={Login_user_info}
              setLogin_user_info={setLogin_user_info}
              Users={Users}
              shiftStartDay={shiftStartDay}
              setShiftStartDay={setShiftStartDay}
              display_user_info={display_user_info}
              setDisplay_user_info={setDisplay_user_info}
            />
          </Route>
          {/* 勤怠承認画面 */}
          <Route exact path="/Approvals">
            <Approvals 
              login_user_id={login_user_id}
              setLogin_user_id={setLogin_user_id}
              setKintai_bscs={setKintai_bscs}
              Kintai_bsc={Kintai_bsc}
              setKintai_bsc={setKintai_bsc}
              Shift_msts={Shift_msts}
              Department_msts={Department_msts}
              setLogin_user_info={setLogin_user_info}
              Users={Users}
              Login_user_info={Login_user_info}
              setDisplay_user_info={setDisplay_user_info}
              setRequest_overtimes={setRequest_overtimes}
              setRequest_paid_holiday={setRequest_paid_holiday}
              setShiftStartDay={setShiftStartDay}

            />
          </Route>
          {/* 承認対象者一覧画面 */}
          <Route exact path="/ApprovalsUserList">
            <ApprovalUserList 
              login_user_id={login_user_id}
              setLogin_user_id={setLogin_user_id}
              Users={Users}
              Shift_msts={Shift_msts}
              Department_msts={Department_msts}
              setKintai_bscs={setKintai_bscs}
              setShiftStartDay={setShiftStartDay}
              setDisplay_user_info={setDisplay_user_info}
              Login_user_info={Login_user_info}
            />
          </Route>
          {/* シフトマスタ編集画面 */}
          <Route exact path="/RequestShiftMstPage">
            <RequestShiftMstPage
              setLogin_user_id={setLogin_user_id}
              setKintai_bscs={setKintai_bscs}
              Login_user_info={Login_user_info}
              setDisplay_user_info={setDisplay_user_info}
              Shift_msts={Shift_msts}
              setShift_msts={setShift_msts}
              Department_msts={Department_msts}
            />
          </Route>
          {/* 部署マスタ編集画面 */}
          <Route exact path="/Mst_depertmentMstPage">
            <Mst_departmentMstPage
              setLogin_user_id={setLogin_user_id}
              setKintai_bscs={setKintai_bscs}
              Login_user_info={Login_user_info}
              setDisplay_user_info={setDisplay_user_info}
              Users={Users}
              setUsers={setUsers}
              Department_msts={Department_msts}
            />
          </Route>
          {/* ユーザマスタ編集画面 */}
          <Route exact path="/Mst_UserMstPage">
            <Mst_UserMstPage
              setLogin_user_id={setLogin_user_id}
              setKintai_bscs={setKintai_bscs}
              Login_user_info={Login_user_info}
              setDisplay_user_info={setDisplay_user_info}
              Users={Users}
              setUsers={setUsers}
              Department_msts={Department_msts}
              setShiftStartDay={setShiftStartDay}
            />
          </Route>
          {/* 公休マスタ編集画面 */}
          <Route exact path="/Mst_publicHolidayMstPage">
            <Mst_publicHolidayMstPage
              setLogin_user_id={setLogin_user_id}
              setKintai_bscs={setKintai_bscs}
              Login_user_info={Login_user_info}
              setDisplay_user_info={setDisplay_user_info}

            />
          </Route>
          {/* CSV出力画面 */}
          <Route exact path="/ExportCsvPage">
            <ExportCsvPage
              setLogin_user_id={setLogin_user_id}
              setKintai_bscs={setKintai_bscs}
              Login_user_info={Login_user_info}
              setDisplay_user_info={setDisplay_user_info}
              Shift_msts={Shift_msts}
              setShift_msts={setShift_msts}
              Users={Users}
              Department_msts={Department_msts}
            />
          </Route>
          {/* タッチパネル用打刻画面 */}
          <Route exact path="/ClockinPage">
            <ClockInPage
              Clockin_info={Clockin_info}
              setClockin_info={setClockin_info}
              Shift_msts={Shift_msts}
              Users={Users}
              Department_msts={Department_msts}
              Login_user_info={Login_user_info}
            />
          </Route>
          {/* SP勤怠入力画面 */}
          <Route exact path="/SP_KintaiPage">
            <SP_KintaiPage
              Kintai_bscs={Kintai_bscs}
              setKintai_bscs={setKintai_bscs}
              Shift_msts={Shift_msts}
              Department_msts={Department_msts}
              Login_user_info={Login_user_info}
              // setLogin_user_info={setLogin_user_info}
            />
          </Route>
          {/* 環境変数マスタ編集画面 */}
          <Route exact path="/EnvMstPage">
            <EnvMstPage
              setLogin_user_id={setLogin_user_id}
              setKintai_bscs={setKintai_bscs}
              Login_user_info={Login_user_info}
              setDisplay_user_info={setDisplay_user_info}
            />
          </Route>
          {/* CSV出力項目設定マスタ編集画面 */}
          <Route exact path="/ExportCSVMstPage">
            <ExportCSVMstPage
              setLogin_user_id={setLogin_user_id}
              setKintai_bscs={setKintai_bscs}
              Login_user_info={Login_user_info}
              setDisplay_user_info={setDisplay_user_info}
              Shift_msts={Shift_msts}
            />
          </Route>
          {/* 打刻ログ画面 */}
          <Route exact path="/ClockinLogPage">
            <ClockinLogPage
              setLogin_user_id={setLogin_user_id}
              setKintai_bscs={setKintai_bscs}
              Login_user_info={Login_user_info}
              setDisplay_user_info={setDisplay_user_info}
              Shift_msts={Shift_msts}
              setShift_msts={setShift_msts}
              Users={Users}
              Department_msts={Department_msts}
            />
          </Route>
          {/* 勤務シフトCSVインポート画面 */}
          <Route exact path="/ImportShiftPage">
            <ImportShiftPage
              setLogin_user_id={setLogin_user_id}
              setKintai_bscs={setKintai_bscs}
              Login_user_info={Login_user_info}
              setDisplay_user_info={setDisplay_user_info}

              Shift_msts={Shift_msts}
              Users={Users}
              setLogin_user_info={setLogin_user_info}
            />
          </Route>
          {/* シフトテーブル画面 */}
          <Route exact path="/ShiftTablePage">
            <ShiftTablePage
              setLogin_user_id={setLogin_user_id}
              setKintai_bscs={setKintai_bscs}
              Login_user_info={Login_user_info}
              setDisplay_user_info={setDisplay_user_info}
            />
          </Route>
          {/* お知らせ画面 */}
          <Route exact path="/InformationPage">
            <InformationPage
              setLogin_user_id={setLogin_user_id}
              setKintai_bscs={setKintai_bscs}
              Login_user_info={Login_user_info}
              setDisplay_user_info={setDisplay_user_info}
              InformationFromEnterprise={InformationFromEnterprise}
              InformationFromSystem={InformationFromSystem}
              Users={Users}
            />
          </Route>
          {/* お知らせ編集画面 */}
          {/* <Route exact path="/InformationEditPage"> */}
          <Route exact path="/InformationPage/edit">
            <InformationEditPage
              setLogin_user_id={setLogin_user_id}
              setKintai_bscs={setKintai_bscs}
              Login_user_info={Login_user_info}
              setDisplay_user_info={setDisplay_user_info}
              setInformationFromEnterprise={setInformationFromEnterprise}
              setInformationFromSystem={setInformationFromSystem}
              Users={Users}
            />
          </Route>
          {/* 有給付与ジャーナル確認画面 */}
          <Route exact path="/jarnalView">
            <JarnalViewPage
              setLogin_user_id={setLogin_user_id}
              setKintai_bscs={setKintai_bscs}
              Login_user_info={Login_user_info}
              setDisplay_user_info={setDisplay_user_info}

              apiKey={"paidHolidayJarnal"}
            />
          </Route>
          {/* SP：勤怠リストページ */}
          {/* <Route exact path="/InformationEditPage"> */}
          <Route exact path="/sp_kintaiListPage">
            <SP_KintaiListPage
              Login_user_info={Login_user_info}
              display_user_info={display_user_info}
              setDisplay_user_info={setDisplay_user_info} 
              login_user_id={login_user_id} 
              
              setLogin_user_id={setLogin_user_id} 
              Kintai_bscs={Kintai_bscs} 
              setKintai_bscs={setKintai_bscs}
              Shift_msts={Shift_msts}
              Kintai_bsc={Kintai_bsc}
              setKintai_bsc={setKintai_bsc} 
              Department_msts={Department_msts}
              shiftStartDay={shiftStartDay}
              setShiftStartDay={setShiftStartDay}
              setRequest_overtimes={setRequest_overtimes}
              setRequest_paid_holiday={setRequest_paid_holiday}
              Users={Users}
            />
          </Route>
          {/* SP：シフトテーブルページ */}
          {/* <Route exact path="/InformationEditPage"> */}
          <Route exact path="/sp_ShiftTablePage">
            <SP_ShiftTablePage
              Login_user_info={Login_user_info}
              display_user_info={display_user_info}
              setDisplay_user_info={setDisplay_user_info} 
              login_user_id={login_user_id} 
            />
          </Route>

        </Switch>
      </BrowserRouter>
    </GlobalProvider>
  );
}

export default App;
